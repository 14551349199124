<template>
    <b-modal :id="id">
      <template #modal-title>
        Fehler!
        <span v-if="status > 0">{{ details.status }}</span>
        <span v-if="status > 0 && (details?.error || details?.title)"> - </span>
        <span v-if="details?.error || details?.title">{{ details.error || details.title }}</span>
      </template>
      <template #default>
        <div v-if="status == 500">Es ist ein interner Fehler aufgetreten:</div>
        <div v-else-if="status == 503">MyEmons is currently down for maintenance. Please try again in a few minutes.</div>
        <div v-else-if="status == 404">Die angeforderte Ressource wurde nicht gefunden:</div>
        <div v-else-if="status == 403">Sie haben keine Berechtigung für die angeforderte Ressource:</div>
        <div v-else-if="status == 401">Der Zugriff auf die angeforderte Ressource wurde verweigert:</div>
        <div v-else-if="status == 400">Die Anfrage konnte nicht bearbeitet werden:</div>
        <div v-else-if="status == -400">Das Formular wurde nicht korrekt ausgefüllt - bitte überprüfen Sie die folgenden Felder:</div>
        <div v-else>Es ist ein Fehler aufgetreten:</div>

        <div v-if="details?.timestamp || details?.path || details?.message || details?.errors || details?.detail">
          <div class="mt-2">
            <ul>
              <li v-if="details?.timestamp"><strong>Zeitstempel:</strong> {{ details.timestamp }}</li>
              <li v-if="details?.path || details?.instance"><strong>Anfrage:</strong> {{ method }} {{ details.path || details.instance }}</li>
              <li v-if="details?.message"><strong>Fehlermeldung:</strong> {{ details.message }}</li>
              <li v-for="(error, idx) in details?.errors"><strong>{{translateFieldName(error?.field)}}</strong>: {{$t(error?.defaultMessage)}}</li>
              <li v-for="(error, idx) in details?.detail?.split('\n')"><strong>Fehler: </strong> {{ error }}</li>
            </ul>
          </div>
        </div>
        <div v-else-if="status != 503">
          <div class="w-100" v-if="typeof(details) == 'string'">{{ details }}</div>
          <textarea class="w-100" style="min-height: 100px;">{{ JSON.stringify(details) }}</textarea>
        </div>
      </template>
      <template #modal-footer="{ok}">
        <b-button variant="primary" @click="ok()">{{ $t('controls.ok') }}</b-button>
      </template>
    </b-modal>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    id: {
      type: String,
      default: 'errorModal'
    }
  },
  data() {
    return {
      details : null,
      method: null,
      status: null,
    }
  },
  methods: {
    show(error) {
      this.details = error?.response?.data || error
      this.method =  error?.config?.method || ""
      this.status =  error?.response?.data?.status || error?.response?.status || error?.status
      this.method = this.method.toUpperCase()
      this.$bvModal.show(this.id)
    },
    translateFormField(fieldname) {
      const key = 'form-fields.' + fieldname
      const val = this.$t(key)
      if (val == key) {
        return fieldname
      }
      return val
    },
    translateSectionName(sectionName) {
      let key, val
      let match = sectionName.match(/(.*)\[([0-9]+)\]$/)
      if (match) {
        this.$log('DEBUG', 'match', match)
        key = "shipment." + match[1]
        let item = parseInt(match[2]) + 1
        val = this.$t(key, {"_item_": item})
      } else {
        key = 'shipment.' + sectionName
        val = this.$t(key)
      }
      if (val == key) {
        return sectionName
      }
      return val
    },
    translateFieldName(field) {
      const lastDot = field.lastIndexOf('.')
      if (lastDot >= 0) {
        return this.translateSectionName(field.substring(0, lastDot)) + " - " + this.translateFormField(field.substring(lastDot + 1))
      } else {
        return this.translateFormField(field)
      }
    }
  }
}
</script>

<style scoped>
</style>