var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.mailLog, function (entry) {
    return _c('mail-log-entry', {
      key: entry.id,
      attrs: {
        "value": entry
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }