<template>
  <validation-observer vid="inquiryObserver" ref="inquiry_observer"
                       v-slot="{ invalid, changed, dirty }" slim>
    <b-modal :id="id" :title="$t('shipment.inquiry.for-shipment', {shipment: shipment.shipmentNumber })" @ok="sendInquiry">
      <template #default>
        <validation-provider name="inquiryText" :vid="id + '-inquiryText'" rules="required" v-slot="{ classes, errors }" slim>
          <b-form-textarea type="text" name="inquiryText" :id="id + '-inquiryText'" v-model="inquiryText"
                           :placeholder="$t('shipment.inquiry.your-inquiry')" rows="3" max-rows="6" />
        </validation-provider>

        <div class="mt-2">
          <b-form-file multiple id="attachments" accept="application/pdf" @input="files = files.concat($event)" :placeholder="$t('inquiries.attach-pdf-files')" />
        </div>

      </template>
      <template #modal-footer="{ok, cancel, hide}">
        <b-button variant="secondary" @click="cancel">{{ $t('controls.abort') }}</b-button>
        <b-button variant="primary" @click="ok" :disabled="invalid">{{ $t('controls.send') }}</b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {extend, ValidationObserver, ValidationProvider} from '@emons/emons-vue'
import ShipmentService from "@/services/shipmentService";

export default {
  name: "OrderInquiryModal",
  components: {ValidationObserver, ValidationProvider},
  props: {
    id: {
      type: String,
      default: 'orderInquiryModal'
    },
    shipment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inquiryText : "",
      files: []
    }
  },
  methods: {
    reset() {
      this.inquiryText = ""
      this.files = []
    },
    show() {
      this.reset()
      this.$bvModal.show(this.id)
    },
    sendInquiry : async function() {
      this.$log("DEBUG", 'inquiry', this.inquiryText)
      try {
        await ShipmentService.sendInquiry(this.shipment, this.inquiryText, this.files)
        this.inquiryText = ""
      } catch(error) {
        this.$eventBus.$emit('backend-error', error)
      }
    }
  }
}
</script>

<style scoped>
</style>