<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header role="tab" v-b-toggle="'maillog-' + value.id">
        <strong>{{ value.publicationDate}} - {{value.subject}}</strong>
      </b-card-header>
      <b-collapse :id="'maillog-' + value.id" accordion="mail-accordion" role="tabpanel">
        <b-card-body>
          <div>
            <pre>{{ value.content }}</pre>
          </div>
          <div>{{ $t('inquiries.attachments') }}:
            <ul>
              <li v-for="attachment in value.attachments"><a :href="attachment._links.self.href" :download="attachment.filename" @click.prevent="downloadAttachment(attachment)" target="_blank">{{ attachment.filename }}</a></li>
            </ul>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "MailLogEntry",
  props: {
    value: {
      type: Object
    },
  },
  data() {
    return {
    }
  },
  methods: {
    async downloadAttachment(attachment) {
      return await ApiService.get(attachment?._links?.self?.href, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        console.log("got response", response.headers.type, response.headers, response)
        let blob = new File([response.data], attachment.filename, {type: response.headers['content-type']})
        let url = window.URL.createObjectURL(blob)

        window.open(url)
        /*
        var aDom = document.createElement("a");
        aDom.setAttribute('style', 'display:none');
        aDom.setAttribute('href', url);
        aDom.setAttribute('target', '_blank');
        aDom.setAttribute('download', attachment.filename);
        document.body.appendChild(aDom);
        aDom.click();
        URL.revokeObjectURL(link);
        document.body.removeChild(aDom);
         */
      })
    }
  }
}
</script>
<style scoped>
li {
  margin-left: 40px;
}
</style>