<template>
  <div>
    <mail-log-entry v-for="entry in mailLog" v-bind:key="entry.id" :value="entry" />
  </div>
</template>

<script>
import MailLogEntry from "@/components/maillog/MailLogEntry.vue";
import ShipmentService from "@/services/shipmentService";

export default {
  name: "MailLog",
  components: {MailLogEntry},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  asyncComputed: {
    mailLog: async function() {
      const link = this.value?._links?.self?.href + "/mails"

      if (link) {
        this.$emit('enableSpinner')
        try {
          const log = await ShipmentService.getMailLog(link)
          if (log) {
            return log.reverse()
          }
        } finally {
          this.$emit('disableSpinner')
        }
      }
      return []
    }

  }
}
</script>

<style scoped>

</style>