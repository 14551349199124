var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accordion",
    attrs: {
      "role": "tablist"
    }
  }, [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'maillog-' + _vm.value.id,
      expression: "'maillog-' + value.id"
    }],
    attrs: {
      "role": "tab"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.value.publicationDate) + " - " + _vm._s(_vm.value.subject))])]), _c('b-collapse', {
    attrs: {
      "id": 'maillog-' + _vm.value.id,
      "accordion": "mail-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_c('div', [_c('pre', [_vm._v(_vm._s(_vm.value.content))])]), _c('div', [_vm._v(_vm._s(_vm.$t('inquiries.attachments')) + ": "), _c('ul', _vm._l(_vm.value.attachments, function (attachment) {
    return _c('li', [_c('a', {
      attrs: {
        "href": attachment._links.self.href,
        "download": attachment.filename,
        "target": "_blank"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.downloadAttachment(attachment);
        }
      }
    }, [_vm._v(_vm._s(attachment.filename))])]);
  }), 0)])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }